import React from 'react';
import { graphql } from 'gatsby';
import { PartnershipPage } from '@upskillist/gatsby-theme-course-landing-pages/src/index';

const HomePage = ({data}) => {
  const pageContext = {
    locale: 'en',
  };
  return (
    <PartnershipPage data={data} pageContext={pageContext} />
  );
};

HomePage.defaultProps = {
  data: {},
};

export default HomePage;

export const pageQuery = graphql`
  query PartnershipPageByUnknownForNotFound {
    brandWebsitePartnerlandingpage(partneridentifier: {eq: "unknown"}) {
      id
      locale
      title
      url
      herobannersubheading
      partneridentifier
      offering
    }
    brandEducationBrandcourses(
      brandidentifier: {eq: "learning.upskillist.com"}
      locale: {eq: "en"}
    ) {
      coursecollections {
        label
        courses {
          courseslug
          coursename
        }
      }
      locale
    }
    courseImageAssets: allS3Object(filter: {Key: {regex: "/(course-images/).*/"}}) {
      nodes {
        Key
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    partnerImageAsset: s3Object(Key: {eq: "partners/unknown/header-logo.png"}) {
      Key
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;
